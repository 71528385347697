<template>
  <div class="container">
    <div class="article-content bgf" v-html="content" />
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'

export default {
  name: 'StudentProtocol',
  mixins: [PageMixin],
  data() {
    return {
      content: ''
    }
  },
  created() {
    this.$api.premium_agreement().then(res => {
      this.content = res.data.agreement
    })
  },
  methods: {

  }
}
</script>
<style lang="less" scoped>

.article-content{
  padding: 10px 15px;
}
</style>

